import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import {
  P2,
  Tile,
  BackButton,
  StyledLink,
  Badge,
  A,
} from '../components/page-elements';
import Container from '../components/container';

import MailIcon from '../images/svg/mail.inline.svg';
import PhoneIcon from '../images/svg/phone.inline.svg';
import WebsiteIcon from '../images/svg/website.inline.svg';
import TwitterIcon from '../images/svg/twitter.inline.svg';
import LinkedinIcon from '../images/svg/linkedin.inline.svg';

// Styled Components
const StyledContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing[40]};
  text-align: left;
`;

const MemberTileDiv = styled(Tile)`
  margin-bottom: ${({ theme }) => theme.spacing[10]};
  box-shadow: ${({ theme }) => theme.boxShadow.md};
  display: flex;
  flex-direction: column;

  @media (min-width: 64em) {
    flex-direction: row;
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  padding: 0 ${({ theme }) => theme.spacing[4]};
  width: 100%;
`;

const Logo = styled(Img)`
  height: 100%;
  width: 100%;
  max-height: 100px;
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing[10]};

  @media (min-width: 37.5em) {
    margin-left: auto;
    margin-right: 0;
    max-height: 145px;
    max-width: 250px;
  }
  @media (min-width: 64em) {
    margin-right: auto;
    max-height: 175px;
    max-width: 300px;
  }
`;

const DetailsContainer = styled.div`
  /* border: 1px solid red; */
  padding: 0 0;
  flex: 2;
  text-align: left;
  max-width: 100%;

  @media (min-width: 24em) {
    padding: 0 ${({ theme }) => theme.spacing[5]};
  }
  @media (min-width: 48em) {
    padding: 0 ${({ theme }) => theme.spacing[12]};
  }
`;

const CompanyName = styled.h2`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: ${({ theme }) => theme.fontSize['4xl']};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: ${({ theme }) => theme.colors.secondary[200]};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing[3]};

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize['5xl']};
  }
`;

const TagLine = styled.h3`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.secondary[400]};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing[10]};

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
`;

const Bio = styled(P2)`
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.spacing[3]};
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[10]};
  > a {
    max-width: 100%;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const SocialBadge = styled(Badge)`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: ${({ theme }) => theme.spacing[3]};

  & > svg {
    min-height: ${({ theme }) => theme.fontSize.lg};
    min-width: ${({ theme }) => theme.fontSize.lg};
    margin-left: 0;
  }
`;

const MemberPage = ({ data }) => {
  const member = data.sanityMember;

  return (
    <Layout>
      <StyledContainer>
        <StyledLink to="/members">
          <BackButton>Back to members</BackButton>
        </StyledLink>
        <MemberTileDiv>
          <LogoContainer>
            <Logo
              fluid={member.logo.asset.fluid}
              imgStyle={{
                objectFit: 'contain',
              }}
            />
          </LogoContainer>
          <DetailsContainer>
            <CompanyName>{member.companyName}</CompanyName>
            <TagLine>{member.tagline}</TagLine>
            <Bio>{member.description && member.description}</Bio>
            <ContactContainer>
              {member.website && (
                <A href={`${member.website}`} rel="external" target="_blank">
                  <Badge>
                    {/* Remove 'https://' or similar */}
                    {member.website.replace(/(^\w+:|^)\/\//, '')}
                    <WebsiteIcon />
                  </Badge>
                </A>
              )}
              {member.email && (
                <A href={`mailto:${member.email}`} rel="external">
                  <Badge>
                    {member.email}
                    <MailIcon />
                  </Badge>
                </A>
              )}
              {member.phone && (
                <A href={`tel:${member.phone}`} rel="external">
                  <Badge>
                    {member.phone}
                    <PhoneIcon />
                  </Badge>
                </A>
              )}
            </ContactContainer>
            <SocialContainer>
              {member.twitter && (
                <A href={`${member.twitter}`} rel="external" target="_blank">
                  <SocialBadge>
                    <TwitterIcon />
                  </SocialBadge>
                </A>
              )}
              {member.linkedin && (
                <A href={`${member.linkedin}`} rel="external" target="_blank">
                  <SocialBadge>
                    <LinkedinIcon />
                  </SocialBadge>
                </A>
              )}
            </SocialContainer>
          </DetailsContainer>
        </MemberTileDiv>
      </StyledContainer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    sanityMember(slug: { current: { eq: $slug } }) {
      companyName
      description
      email
      linkedin
      logo {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      phone
      tagline
      twitter
      website
    }
  }
`;

export default MemberPage;
